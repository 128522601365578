<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;" @click="hide()">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="send-custom-offer-main">
          <div class="close-div">
            <img  @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
                  src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
          </div>
          <div>
            <label class="custom-offer-label" style="margin-top: 14px;">Şikayet nedenini birkaç cümleyle anlatır mısın? Karşı taraf, şikayet talebinden haberdar olmayacak. 🤫</label>
            <textarea maxlength="1000" v-model="textArea" style="width: 455px; height: 100px;"></textarea>


            <div style="text-align: left">


              <div style="display: flex; align-items: center; height: 18px; margin-top: 20px; margin-bottom: 20px;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                <p @click="chooseFiles('order_support_upload')" class="start-upload-text">Dosya Ekle</p>
                <input id="order_support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, 'blog')">
              </div>

              <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                <div style="display: flex; align-items: center">
                  <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>
                  <div v-else @click="deleteFile(file.externalID, 'blog')">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>

                  <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                  <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                  <div style="display: flex; flex-direction: column; margin-left: 5px;">
                    <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                    <progress v-if="$store.state.percentCompleted.blog[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.blog[file.stamp]' max="100"></progress>
                  </div>
                </div>
              </div>

            </div>


          </div>
          <div class="seperator"></div>
          <button :disabled="buttonLoading || !textArea" @click="sendComplaint()" class="super-button" style="">Gönder</button>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-complaint-v1",
    data() {
      return {
        type: null,
        externalId: null,
        reportTypeId: null,
        textArea: "",
        supportFiles:[],
        buttonLoading:false,
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      chooseFiles(id) {
        document.getElementById(id).click()
      },

      cancelUpload(timeStamp){
        this.cancelUploadRequest(parseInt(timeStamp));

        let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
        if(supportFileIndex !== -1){
          this.supportFiles.splice(supportFileIndex, 1);
        }
      },

      deleteFile(uploadID, type='blog') {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.supportFiles = this.supportFiles.filter(function (file) {
                return file.externalID !== uploadID
              });

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      onFileChange(e, type='blog') {

        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.buttonLoading = true;

        let timeStamp = +new Date();


        let rand = Math.floor(Math.random() * 1000) + 1;
        timeStamp = timeStamp + rand;
        this.supportFiles.push({
          url: null,
          externalID: timeStamp,
          filename: files[0].name,
          stamp: timeStamp
        });

        this.uploadFileToGoogle(files[0], type, null, timeStamp)
          .then(result => {
            let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
            let supportFile = this.supportFiles[supportFileIndex];
            supportFile.externalID = result.externalID;
            supportFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName,
              supportFile.icon_png = result.icon_png;
            this.supportFiles[supportFileIndex] = supportFile;


            this.buttonLoading = false;

          }).catch(err => {
          this.buttonLoading = false;

        });


        e.target.value = ''

      },

      sendComplaint() {
        this.buttonLoading = true;
        let IDs = [];
        this.supportFiles.forEach(function (file) {
          IDs.push(file.externalID);
        });
        this.api.general.report(this.type, this.externalId, this.reportTypeId, this.textArea, IDs)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.buttonLoading = false;
              this.hide();
            } else {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          });
      }
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info) {
          this.type = info.type;
          this.externalId = info.externalId;
          this.reportTypeId = info.reportTypeId;
        }
        return true;
      }
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: 100vw !important;
    max-width: 690px !important;
    height: 100%;
    max-height: 535px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }

  .send-custom-offer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    padding: 50px 100px 50px 100px;
  }


  .custom-offer-label {
    font-size: 15px;
    text-align: left;
    color: #5e6b79;
    line-height: 1.4;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .price-duration {
    display: flex;
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
    margin-top: 30px;
    margin-bottom: 18px;
  }

  .super-button {
    min-width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #2d3640;
    &:hover {
      background-color: #303b51;
    }
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }


  .delete-icon /deep/ {
    cursor: pointer;
    width: 14px;
    height: 14px;
    path {
      fill: #8b95a1;
    }
  }

  .attach-icon /deep/ {
    width: 14px;
    height: 14px;
    path {
      fill: #2d3640;
    }
  }

</style>
